<template>
    <div class = "opc">
         <a-layout style="background: #fff">
              <a-layout-header style="background: #fff">
                 <div class="warp">
                      <div class = "header-box">
                      <img class = "logo" tabindex = "0" src = "../assets/img/api-logo.png">
                      <span class = "head-title" tabindex = "0">开放平台</span>
                      <a-menu v-model="current" mode="horizontal" class="header-menu" @click="changeMenu">
                          <a-menu-item key="0">首页 </a-menu-item>
                          <a-menu-item key="1">API服务 </a-menu-item>
                          <a-menu-item key="2">文档中心 </a-menu-item>
                        </a-menu>
                  </div>
                 </div>
              </a-layout-header>
              <div class="content">
                  <div class="enterprise-console-container">
                        <router-view></router-view>
                  </div>
              </div>
              <a-layout-footer style="background: #fff"></a-layout-footer>
        </a-layout>
    </div>
</template>

<script>
export default {
    name: "opc",
    data() {
        return {
            current:"1",

        }
    },
    created() {
        console.log('opc');
    },
    methods: {
        changeMenu(item){
            if(item.key == 0){
                this.$jumpTo('/');
            } else if (item.key == '1') {
                this.$jumpTo('/opc/api-service');
            } else if (item.key == '2') {
                this.$jumpTo('/opc/document');
            }
        },
        toggleCollapsed() {
            this.collapsed = !this.collapsed;
        },
    }
}
</script>

<style lang="less" scoped>
.opc{
    background-color: #fff;
}
    .warp {
        width: 1200px;
        padding: 10px 0;
        margin: 0 auto;
        background-color: #fff;

        .header-box {
            height: 50px;
            line-height: 50px;
            .logo {
                cursor: pointer;
                max-width: 65px;
                margin-right: 13px;
            }

            .head-title {
                cursor: pointer;
                color: #333;
                font-weight: 500;
                font-size: 22px;
                vertical-align: top;
            }
            .header-menu {
                float: right;
            }
        }
    }
       .content {
            width: 100%;
            min-height: 851px;
            background: url('../assets/img/star-background.png') no-repeat top #f5f6fa;
        }
    .enterprise-console-container{
        margin: 0 auto;
        width: 1200px;
        padding-top: 156px;
        padding-bottom: 37px;
        overflow: hidden;
    }
</style>