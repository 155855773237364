<template>
    <div class="goods">
        <a-spin :spinning="spinning">

        <cHeader></cHeader>
        <cMenu></cMenu>
        <div class="goods-path ng-star-inserted">
            <div class="main d-flex align-items-center">
                <div class="col-breadcrumb">
                    <div class="ant-breadcrumb">
                        <a-breadcrumb>
                            <a-breadcrumb-item>首页</a-breadcrumb-item>
                            <a-breadcrumb-item>{{goodsInfo.cate1_title}}</a-breadcrumb-item>
                            <a-breadcrumb-item>{{goodsInfo.cate2_title}}</a-breadcrumb-item>
                        </a-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
        <div class="main ng-star-inserted">
            <div class="goods-wrap clearfix">
                <div class="goodsimg-wrap">
                    <div>
                        <div class="img-box">
                            <!--                            <div class="big-img"><img :src="goodsInfo.logo"></div>-->

                            <a-carousel arrows dots-class="slick-dots slick-thumb">
                                <a slot="customPaging" slot-scope="props">
                                    <img style="width: 60px; height: 48px;" :src="getImgUrl(props.i)"/>
                                </a>
                                <div v-for="(item, idx) in goodsInfo.carousel" :key="idx">
                                    <img :src="item"/>
                                </div>
                            </a-carousel>
                        </div>
                    </div>
                </div>
                <div class="goods-info clearfix">
                    <div class="goods-title"> {{goodsInfo.title}}</div>
                    <div class="goods-price">
                        <ul>
                            <li class="clearfix">
                                <div class="col-left">分销价：</div>
                                <div class="col-right">
                                    <div class="s-red ng-star-inserted">￥{{goodsInfo.base_price}}</div>
                                </div>
                            </li>
                            <li class="clearfix">
                                <div class="col-left">建议零售价：</div>
                                <div class="col-right">￥{{goodsInfo.market}}</div>
                            </li>
                            <li class="clearfix">
                                <div class="col-left">市场价：</div>
                                <div class="col-right">￥{{goodsInfo.cost}}</div>
                            </li>
                            <li class="clearfix">
                                <div class="col-left">预估毛利/毛利率：</div>
                                <div class="col-right" style="display: flex;">
                                    <div class="s-gray ng-star-inserted">￥{{parseFloat(goodsInfo.market - goodsInfo.base_price).toFixed(2)}} |
                                        {{parseFloat((goodsInfo.market - goodsInfo.base_price) / goodsInfo.market *
                                        100).toFixed(2)}}%
                                    </div>
                                    <span nz-tooltip="" nztooltipplacement="top"
                                          nztooltiptitle="预估毛利率=（建议零售价 - 分销价）/ 建议零售价 × 100%，计算结果四舍五入，存在误差；价格均指含税价；毛利率为预估值，因实际市场零售价会变化，仅供参考"
                                          nztooltipcolor="#108ee9"><a-icon type="question-circle"/></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="goods-info-list">
                        <div nztitle="" class="ant-descriptions goods-desc">
                            <div class="ant-descriptions-view">
                                <table>
                                    <tbody>
                                    <tr class="ant-descriptions-row ng-star-inserted">
                                        <td class="ant-descriptions-item ng-star-inserted" colspan="1"><span
                                                class="ant-descriptions-item-label ant-descriptions-item-colon"> 总销量 </span><span
                                                class="ant-descriptions-item-content">{{goodsInfo.number_sales}}件</span>
                                        </td>
                                    </tr>
                                    <tr class="ant-descriptions-row ng-star-inserted">
                                        <td class="ant-descriptions-item ng-star-inserted" colspan="1"><span
                                                class="ant-descriptions-item-label ant-descriptions-item-colon"> 商品编号 </span><span
                                                class="ant-descriptions-item-content">{{goodsInfo.id}}</span></td>
                                        <td class="ant-descriptions-item ng-star-inserted" colspan="1"><span
                                                class="ant-descriptions-item-label ant-descriptions-item-colon"> 总库存 </span><span
                                                class="ant-descriptions-item-content">{{goodsInfo.number_stock}}</span>
                                        </td>
                                    </tr>
                                    <tr class="ant-descriptions-row ng-star-inserted">
                                        <!--                                        <td class="ant-descriptions-item ng-star-inserted" colspan="1"><span-->
                                        <!--                                                class="ant-descriptions-item-label ant-descriptions-item-colon"> 销售范围 </span><span-->
                                        <!--                                                class="ant-descriptions-item-content"><span nz-tooltip=""-->
                                        <!--                                                                                            nztooltipplacement="right"-->
                                        <!--                                                                                            nztooltipcolor="#108ee9"-->
                                        <!--                                                                                            nztooltipoverlayclassname="area-list"-->
                                        <!--                                                                                            class="ng-star-inserted"> 20个省份，216个城市 <a-icon type="question-circle" theme="filled" /></span></span>-->
                                        <!--                                        </td>-->
                                        <td class="ant-descriptions-item ng-star-inserted" colspan="1"><span
                                                class="ant-descriptions-item-label ant-descriptions-item-colon"> 运费 </span><span
                                                class="ant-descriptions-item-content"> 包邮 </span></td>
                                    </tr>
                                    <tr class="ant-descriptions-row ng-star-inserted">
                                        <td class="ant-descriptions-item ng-star-inserted" colspan="2"><span
                                                class="ant-descriptions-item-label ant-descriptions-item-colon"> 商品状态 </span><span
                                                class="ant-descriptions-item-content"><span
                                                class="ng-star-inserted">{{goodsInfo.status ? '在售' : '下架'}}</span></span>
                                        </td>
                                        <td class="ant-descriptions-item ng-star-inserted" colspan="2"><span
                                                class="ant-descriptions-item-label ant-descriptions-item-colon"> 商品类型 </span><span
                                                class="ant-descriptions-item-content"><span
                                                class="ng-star-inserted">{{goodsInfo.type == 1 ? 'cloud' : 'local'}}</span></span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="goods-spec">
                            <div class="goods-spec-label">规格</div>
                            <div class="goods-spec-list ng-star-inserted">
                                <div class="speclist">
                                    <ul>
                                        <li class="ng-star-inserted"
                                            v-for="(item, idx) in skuList"
                                            :key="idx">
                                            <img alt="" class="item-img" :src="goodsType == 1 ? item[0].sku_thumb : item.sku_thumb">

                                            <div class="sepc-col-right">
                                                <div class="spec-cell">
                                                    <div class="item-name"><span class="ng-star-inserted">{{goodsType == 1 ? item[0].key : item.key}}</span>
                                                    </div>
                                                    <div class="item-price"><span class="ng-star-inserted">￥{{goodsType == 1 ? item[0].base_price : item.base_price}}</span>
                                                    </div>
<!--                                                    <div class="item-num">1件起购</div>-->
                                                </div>
                                                <div class="spec-cell">
                                                    <div class="item-code">编号 {{goodsType == 1 ? item[0].sku : item.sku}}</div>
                                                    <div class="item-inv"> 库存<span class="ng-star-inserted">{{goodsType == 1 ? item[0].stock_total : item.stock}}件</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </li>
                                    </ul>
                                </div>
                                <div class="row-pages">
                                    <a-pagination :total="total" :pageSize="pageSize" show-less-items :show-total="total => `共 ${total} 种商品`"
                                                  @change="changePage"/>
                                </div>
                                <div class="row-buy">

                                    <div v-if="goodsInfo.in_lib" class="btn-buy no" style="cursor: pointer;">
                                        <div class="item-icon"></div>
                                        <div class="item-text">已加入我的商品库</div>
                                    </div>
                                    <div v-else class="btn-buy" style="cursor: pointer;" @click="addToMyLib(goodsInfo.id)">
                                        <div class="item-icon"></div>
                                        <div class="item-text">加入我的商品库</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail-title"><span>商品详情</span></div>
            <div class="detail-prop">
                <div nztitle="" class="ant-descriptions ng-star-inserted">
                    <div class="ant-descriptions-view">
                        <table>
                            <tbody></tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="detail-imglist ng-star-inserted" v-html="goodsInfo.content">
            </div>
        </div>
        <cFooter></cFooter>
        </a-spin>
    </div>
</template>

<script>

    import cHeader from '../component/header';
    import cFooter from '../component/footer';
    import cMenu from '../component/menu';
    import service from "../../utils/request";

    export default {
        name: "goods",
        components: {
            cHeader, cFooter, cMenu
        },
        data() {
            return {
                spinning: false,
                goodsId: 0,
                goodsType: 0,
                goodsInfo: {},
                page: 1,
                pageSize: 10,
                total: 0,
                skuList: []
            }
        },
        created() {
            this.goodsId = this.$route.params.id;
            this.goodsType = this.$route.params.type;
            this.getInfo();
        },
        methods: {
            getInfo() {
                this.spinning = true;
                service.post(service.uri.goods.getInfo, {goods_id: this.goodsId, goods_type:this.goodsType}).then(res => {
                    this.spinning = false;
                    if (res.code == 200) {
                        this.goodsInfo = res.data;
                        this.total = this.goodsInfo.lists.length;
                        this.changeSkuList();
                    }
                });
            },
            getImgUrl(i) {
                return this.goodsInfo.carousel[i];
            },
            changeSkuList() {
                let start = (this.page - 1) * this.pageSize;
                this.skuList = this.goodsInfo.lists.slice(start, start + this.pageSize);
            },
            changePage(e) {
                console.log('page', e)
                this.page = e;

                this.changeSkuList();
            },
            addToMyLib(spuId){
                this.spinning = true;
                service.post(service.uri.goods.addToMyLib, {spu_id: spuId, goods_type: this.goodsType}).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.goodsInfo.in_lib = true;
                    }
                });
            },
        }
    }
</script>

<style lang="less" scoped>

    .main {
        width: 1200px;
        margin: 0 auto;
    }

    .clearfix:after {
        clear: both;
    }

    dl, dt, ul, li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .ant-breadcrumb {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, .65);
        font-variant: tabular-nums;
        height: 44px;
        line-height: 44px;
        list-style: none;
        font-feature-settings: "tnum";
        font-size: 14px;

        .el-breadcrumb {
            height: 44px;
            line-height: 44px;
        }
    }

    .ant-descriptions-view {
        width: 100%;
        overflow: hidden;
        border-radius: 2px;

        table {
            width: 100%;
            table-layout: fixed;
        }
    }

    .goods-path {
        height: 44px;
        background-color: #f7f7f7;

        .main {
            height: 44px;
        }

        .goods-path .col-breadcrumb {
            flex: 1;
        }
    }

    .goods-wrap {
        padding: 20px;


        .ant-carousel {
            .slick-dots {
                height: auto;

                li {
                    position: relative;
                    display: inline-block;
                    margin: 0 2px;
                    padding: 0;
                    text-align: center;
                    vertical-align: top;
                    border: red 1px solid;
                }
            }

            .slick-slide {
                img {
                    border: 5px solid #fff;
                    display: block;
                    margin: auto;
                    max-width: 80%;
                }
            }

            .slick-thumb {
                bottom: -45px;

                li {
                    width: 60px;
                    height: 48px;

                    img {

                        width: 100%;
                        height: 100%;
                        filter: grayscale(100%);
                    }
                }

                li.slick-active {
                    img {
                        filter: grayscale(0%);
                    }
                }
            }
        }
    }


    .goodsimg-wrap {
        float: left;
        width: 480px;
    }

    .img-box {
        width: 480px;
    }

    .big-img {
        width: 100%;
        height: 480px;

        img {
            width: 480px;
            height: 480px;
        }
    }

    .scroll-list {
        margin-top: 18px;
        height: 60px;
        display: flex;
        align-items: center;

        .btn-next, .btn-prev {
            width: 30px;
            height: 54px;

            .ant-btn-icon-only {
                height: 54px;

                * {
                    font-size: 12px;
                }
            }

            .ant-btn {
                color: #999;
            }
        }

        .scroll-con {
            flex: 1;
            height: 60px;
            position: relative;
            overflow: hidden;

            ul {
                position: absolute;
                z-index: 2;
                left: 0;
                top: 0;
                transition: all .3;
                display: table;

                li {
                    padding-left: 20px;
                    display: table-cell;

                    img {
                        display: block;
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }
    }

    .goods-info {
        float: right;
        width: 650px;

        .goods-title {
            font-size: 18px;
            color: #333;
            line-height: 25px;
            word-break: break-all;
            margin-bottom: 20px;
        }

        .goods-price {
            margin-bottom: 20px;
            padding: 20px;
            background: url("../assets/img/price-bg.png") repeat 0 0;

            ul {
                li {
                    padding: 9px 0;

                    .col-left {
                        width: 114px;
                        float: left;
                        font-size: 12px;
                        color: #666;
                    }

                    .col-right {
                        float: left;
                        font-size: 14px;
                        color: #333;
                        margin: 0;
                    }
                }
            }
        }

        .goods-info-list {
            padding: 0 0 0 20px;

            .goods-desc {
                .ant-descriptions-row > td, .ant-descriptions-row > th {
                    padding-bottom: 12px;
                }

                .ant-descriptions-item > span {
                    display: inline-block;
                }

                .ant-descriptions-item-label {
                    font-size: 12px;
                    color: #666;
                }

                .ant-descriptions-item-content {
                    font-size: 12px;
                }
            }

            .goods-spec {
                display: flex;

                .goods-spec-label {
                    width: 64px;
                    font-size: 12px;
                    color: #666;
                }

                .goods-spec-list {
                    flex: 1;

                    .speclist {
                        li {
                            height: 52px;
                            background: #fff;
                            border: 1px solid #ededed;
                            display: flex;
                            margin-bottom: 8px;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 12px;
                            padding-right: 4px;

                            .sepc-col-right {
                                flex: 1;
                            }

                            .spec-cell {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            }

                            .item-name {
                                flex: 1;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }

                            .item-img {
                                width: 38px;
                                height: 38px;
                                margin-right: 9px;
                                display: block;
                                padding: 0;
                            }

                            .item-price {
                                min-width: 84px;
                            }

                            .item-num {
                                min-width: 70px;
                            }

                            .item-inv {
                                min-width: 80px;
                            }

                            .item-code {
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                flex: 1;
                            }
                        }
                    }

                    .row-pages {
                        padding-top: 12px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        .col-total {
                            font-size: 12px;
                            color: #666;
                            padding-right: 10px;
                        }

                        .col-pages {
                            .ant-pagination-prev {
                                margin-right: 8px;
                            }
                        }
                    }
                }

                .row-buy {
                    .btn-buy {
                        width: 240px;
                        height: 48px;
                        border: 1px solid #de0202;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        color: #de0000;
                        cursor: pointer;

                        .item-text {
                            padding-left: 8px;
                        }

                        .item-icon {
                            display: block;
                            width: 13px;
                            height: 14px;
                            background: url("../assets/img/lock_red.png") no-repeat 0 0;
                        }
                    }

                    .btn-buy.no {
                        color: #999;
                        border: 1px solid #999;
                        cursor: default;

                        .item-icon {
                            background: url("../assets/img/lock_ash.png") no-repeat 0 0;
                        }
                    }
                }
            }
        }
    }

    .detail-title {
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #ededed;
        position: relative;

        span {
            display: block;
            text-align: center;
            position: absolute;
            z-index: 3;
            width: 120px;
            height: 40px;
            background: #fff;
            box-sizing: border-box;
            border: 1px solid #ededed;
            border-top: 2px solid #de0000;
            font-size: 12px;
            color: #de0000;
            line-height: 38px;
        }
    }

    .detail-prop {
        padding: 20px;
        font-size: 12px;
    }

    .detail-imglist {
        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }
</style>